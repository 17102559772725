import React, { useState, useContext, useEffect } from "react";
import trash from "../images/trash.ico";
import check from "../images/check-mark.ico";
import edit from "../images/edit.ico";
import returns from "../images/x-mark.ico";
import { userContext } from "../context/userContext";
function AddFluidTypeChildren(props) {
  const { role, stationList, getStationList, refreshToken, getFluidList } =
    useContext(userContext);
  const [select, setSelect] = useState();

  const [userImage, setUserImage] = useState([]);
  const [userFds, setUserFds] = useState([]);

  const handleImage = (e) => {
    e.persist();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setUserImage(img);
  };
  const handleFds = (e) => {
    e.persist();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setUserFds(img);
  };

  async function modifyFluidType(event) {
    event.preventDefault();
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/modifyfluidtype`;
      const formData = new FormData();
      formData.append("file", userImage.data);
      formData.append("fds", userFds.data);
      formData.append("tankType", event.target.elements.tankType.value);
      formData.append("oilType", event.target.elements.oilType.value);
      formData.append("station", event.target.elements.modifyStation.value);
      formData.append("fluidTypeId", props.content._id);
      formData.append("lastImage", props.content.image);
      formData.append("lastFds", props.content.fds);

      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
        },
        credentials: "include",
        body: formData,
      };

      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Ajout d'un nouveau type de fluid reussi");
        setSelect(false);
        getFluidList();
        setUserImage("");
        setUserFds("");
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue");
      }
    } else {
      return;
    }
  }
  async function deleteFluidType() {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/deletefluid`;

      const fluidId = {
        fluidId: props.content._id,
        image: props.content.image,
        fds: props.content.fds,
      };
      const data = JSON.stringify(fluidId);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: data,
      };
      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Succès: Type de fluid supprimée");
        getFluidList();
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    getStationList();
    getFluidList();
  }, [role]);
  return (
    <>
      {!select ? (
        <div className="message-array-info ">
          <div className="message-content-container">
            <ul>
              <li>
                <p>Type:</p> {props.content.tankType}
              </li>
              <li>
                <p>Désignation:</p>
                {props.content.oilType}
              </li>
              <li>
                <p>Etablissement:</p> {props.content.station}
              </li>

              <li>
                <p>Image:</p>
                <img
                  className="soon"
                  src={`${process.env.REACT_APP_BAC_URL}/images/fluid/${props.content.image}`}
                  alt="trash"
                  style={{ width: 60 }}
                ></img>{" "}
              </li>
              <li style={{ paddingRight: 80 }}>
                <p>Fds:</p>
                <img
                  className="soon"
                  src={`${process.env.REACT_APP_BAC_URL}/images/fluid/${props.content.fds}`}
                  alt="trash"
                  style={{ width: 60 }}
                ></img>{" "}
              </li>
            </ul>
            <img
              className="edit"
              src={edit}
              alt="edit"
              onClick={() => setSelect(true)}
            ></img>{" "}
            <img
              className="trash"
              src={trash}
              alt="trash"
              onClick={() => deleteFluidType()}
            ></img>{" "}
          </div>
        </div>
      ) : (
        <form className="message-array-info" onSubmit={modifyFluidType}>
          <div className="message-content-container">
            <ul>
              <li>
                <p>Type:</p>
                <input
                  id="tankType"
                  type="texte"
                  placeholder="Bidon,fût..."
                  maxLength="20"
                  required
                  defaultValue={props.content.tankType}
                />
              </li>
              <li>
                <p>Désignation:</p>
                <input
                  id="oilType"
                  type="texte"
                  placeholder="Asphaltine, GG20...."
                  maxLength="20"
                  required
                  defaultValue={props.content.oilType}
                />
              </li>
              <li style={{ paddingRight: 200 }}>
                <p>Etablissement:</p>
                <select
                  id="modifyStation"
                  defaultValue={props.content.station}
                  required
                >
                  {stationList.map((item, key) => (
                    <>
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    </>
                  ))}
                </select>
              </li>
            </ul>
            <ul>
              <li style={{ width: 400 }}>
                <p>Image:</p>
                {userImage.preview ? (
                  <img
                    className="soon"
                    src={`${userImage.preview}`}
                    alt="trash"
                    style={{ width: 100 }}
                  ></img>
                ) : (
                  <img
                    className="soon"
                    src={`${process.env.REACT_APP_BAC_URL}/images/fluid/${props.content.image}`}
                    alt="trash"
                    style={{ width: 100 }}
                  ></img>
                )}
                <input
                  type="file"
                  name="uimage"
                  onChange={handleImage}
                  className="form-control"
                  id="uploadBtn"
                  accept=".png, .jpg, .jpeg, .x-icon, .ico"
                />
              </li>
              <li style={{ width: 400 }}>
                <p>Fds:</p>
                {userFds.preview ? (
                  <img
                    className="soon"
                    src={`${userFds.preview}`}
                    alt="trash"
                    style={{ width: 100 }}
                  ></img>
                ) : (
                  <img
                    className="soon"
                    src={`${process.env.REACT_APP_BAC_URL}/images/fluid/${props.content.fds}`}
                    alt="trash"
                    style={{ width: 100 }}
                  ></img>
                )}
                <input
                  type="file"
                  name="uimage"
                  onChange={handleFds}
                  className="form-control"
                  id="uploadBtn"
                  accept=".png, .jpg, .jpeg, .x-icon, .ico"
                />
              </li>
            </ul>
            <button>
              <img
                className="check"
                src={check}
                alt="check"
                style={{ top: 0, right: 100 }}
              ></img>{" "}
            </button>
            <img
              className="returns"
              src={returns}
              alt="returns"
              onClick={() => {
                setSelect(false);
                setUserImage("");
                setUserFds("");
              }}
              style={{ top: 0 }}
            ></img>{" "}
          </div>
        </form>
      )}
    </>
  );
}

export default AddFluidTypeChildren;
