import React, { useState, useContext, useEffect } from "react";
import trash from "../images/trash.ico";

import check from "../images/check-mark.ico";
import add from "../images/plus.ico";
import returns from "../images/x-mark.ico";
import { userContext } from "../context/userContext";
function Station() {
  const { role, stationList, getStationList, refreshToken } =
    useContext(userContext);

  const [select, setSelect] = useState();
  const [logoStation, setLogoStation] = useState([]);
  const handleImage = (e) => {
    e.persist();
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setLogoStation(img);
  };
  async function addStation(event) {
    event.preventDefault();
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/addstation`;
      const formData = new FormData();
      formData.append("file", logoStation.data);
      formData.append("info", event.target.elements.infoStation.value);
      formData.append("name", event.target.elements.nameStation.value);

      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
        },
        credentials: "include",
        body: formData,
      };

      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Ajout d'un nouvelle etablissement reussi");
        setSelect(false);
        getStationList();
        setLogoStation("");
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue");
      }
    } else {
      return;
    }
  }
  async function deleteStation(id, logo) {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/deletestation`;

      const stationId = {
        stationId: id,
        logo: logo,
      };
      const data = JSON.stringify(stationId);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: data,
      };
      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Succès: station supprimée");
        getStationList();
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    getStationList();
  }, [role]);
  if (role === "admin") {
    return (
      <>
        <div className="engin-array">
          <div className="capteur-title-container">
            <h3>Station/Etablissement</h3>
          </div>
        </div>
        <>
          {stationList ? (
            <>
              {stationList.map((item, key) => (
                <div className="message-array-info " key={key}>
                  <div className="station-container">
                    <ul>
                      <li>
                        <p>Nom:</p> {item.name}
                      </li>
                      <li style={{ width: 400 }}>
                        <p>Info:</p>
                        {item.info}
                      </li>
                      <li style={{ width: 200 }}>
                        <p>Date:</p>
                        {item.date.substring(3, 15)}
                      </li>
                      <li>
                        <img
                          className="station-logo"
                          src={`${process.env.REACT_APP_BAC_URL}/images/stationLogo/${item.logo}`}
                          alt="trash"
                        ></img>{" "}
                      </li>
                    </ul>
                    <img
                      className="trash"
                      src={trash}
                      alt="trash"
                      onClick={() => deleteStation(item._id, item.logo)}
                    ></img>{" "}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
          {!select ? (
            <div className="btn-add-engin">
              <img
                className="add"
                src={add}
                alt="add"
                onClick={() => setSelect(true)}
              ></img>
            </div>
          ) : (
            <form className="message-array-info" onSubmit={addStation}>
              <div className="station-container">
                <ul>
                  <li>
                    <p>Nom:</p>
                    <input
                      id="nameStation"
                      type="texte"
                      placeholder="NSR"
                      maxLength="20"
                      required
                    />
                  </li>
                  <li style={{ width: 200 }}>
                    <p>logo:</p>
                    <input
                      type="file"
                      name="uimage"
                      onChange={handleImage}
                      className="form-control"
                      id="uploadBtn"
                      accept=".png, .jpg, .jpeg, .x-icon, .ico"
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>Info:</p>
                    <input
                      id="infoStation"
                      type="texte"
                      placeholder="Nice saint Rock"
                      maxLength="50"
                      required
                    />
                  </li>
                  <li className="logo-station-input">
                    {logoStation && logoStation.preview ? (
                      <img
                        className="soon"
                        src={logoStation.preview}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    ) : (
                      <img
                        className="soon"
                        src={`${process.env.REACT_APP_BAC_URL}/images/stationLogo/defaultStationLogo.jpg`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    )}
                  </li>
                </ul>
                <li>
                  <button>
                    <img
                      type="submit"
                      className="check"
                      src={check}
                      alt="check"
                      style={{ top: 0, right: 60 }}
                    ></img>{" "}
                  </button>
                </li>
                <li>
                  <img
                    className="returns"
                    src={returns}
                    alt="returns"
                    onClick={() => {
                      setLogoStation("");
                      setSelect(false);
                    }}
                    style={{ top: 0 }}
                  ></img>{" "}
                </li>
              </div>
            </form>
          )}
        </>
      </>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}

export default Station;
