import React from "react";

function ImageVisual(props) {
  return (
    <>
      <div className="image-visu">
        <h3 className="contact-title">{props.imageName} </h3>
        <img src={`${props.imageUrl}`} alt={props.imageName}></img>
      </div>
    </>
  );
}

export default ImageVisual;
