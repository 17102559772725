import React, { useState, useContext, useEffect } from "react";
import { userContext } from "../context/userContext";
import trash from "../images/trash.ico";
import edit from "../images/edit.ico";
import check from "../images/check-mark.ico";
import returns from "../images/x-mark.ico";

function BidonForm(props) {
  const [select, setSelect] = useState(false);
  const [select1, setSelect1] = useState(false);
  const {
    getEnginAvailable,
    refreshToken,
    station,
    getFluidList,
    fluidList,
    role,
  } = useContext(userContext);
  useEffect(() => {
    setSelect1(props.select);
  }, [props.select]);
  async function updateTankAvailable(e) {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/updatetank`;
    const token = localStorage.getItem("XSRF-TOKEN");
    const availableTank = {
      layingDate: e.target.elements.formAddDate.value,
      expirationDate: e.target.elements.formAddDate2.value,
      vac: e.target.elements.formAddVac.value,
      enginAvailibleId: props.idEngin,
      tankType: e.target.elements.grbOilTank.value,
      oilType: e.target.elements.typeTank.value,
      bidonId: props.content._id,
    };
    const data = JSON.stringify(availableTank);
    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: Bidon modifiée");
      getEnginAvailable(station);
      setSelect(false);
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  async function deleteTankAvailable() {
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/deletetank`;
    const token = localStorage.getItem("XSRF-TOKEN");
    const availableTank = {
      bidonId: props.content._id,
      enginAvailibleId: props.idEngin,
    };
    const data = JSON.stringify(availableTank);
    const options = {
      method: "DELETE",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: Matériel supprimée");
      getEnginAvailable(station);
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  useEffect(() => {
    getFluidList();
  }, [role]);
  return (
    <>
      {!select1 ? (
        <></>
      ) : (
        <>
          {!select ? (
            <>
              <div className="engin-array-info " style={{ height: 50 }}>
                <div className="capteur-content-container">
                  <h5>{props.content.tankType}</h5>
                  <h5>{props.content.oilType}</h5>

                  <h5>Vac: {props.content.vac}</h5>
                  <h5>{props.content.layingDate}</h5>
                  <h5>{props.content.expirationDate}</h5>
                  <h3>
                    <img
                      className="edit"
                      src={edit}
                      alt="edit"
                      onClick={() => setSelect(true)}
                      style={{ paddingLeft: 20 }}
                    ></img>{" "}
                  </h3>
                  <h3>
                    <img
                      className="trash"
                      src={trash}
                      alt="trash"
                      onClick={deleteTankAvailable}
                      style={{ paddingLeft: 25 }}
                    ></img>{" "}
                  </h3>
                </div>
              </div>
            </>
          ) : (
            <form className="form-bidon-liste" onSubmit={updateTankAvailable}>
              <div className="engin-array-info" style={{ height: 50 }}>
                <div className="capteur-content-container">
                  <h3>
                    <select
                      id="grbOilTank"
                      defaultValue={props.content.tankType}
                      required
                    >
                      {fluidList.map((item, key) => (
                        <>
                          <option key={key} value={item.tankType}>
                            {item.tankType}
                          </option>
                        </>
                      ))}
                    </select>
                  </h3>
                  <h3>
                    <select
                      id="typeTank"
                      defaultValue={props.content.oilType}
                      required
                    >
                      {fluidList.map((item, key) => (
                        <>
                          <option key={key} value={item.oilType}>
                            {item.oilType}
                          </option>
                        </>
                      ))}
                    </select>
                  </h3>
                  <h3>
                    <input
                      type="texte"
                      id="formAddVac"
                      maxLength="10"
                      required
                      style={{ width: 50, textAlign: "center" }}
                      defaultValue={props.content.vac}
                    ></input>
                  </h3>
                  <h3>
                    <input
                      type="date"
                      id="formAddDate"
                      defaultValue={props.content.layingDate}
                      required
                    ></input>
                  </h3>
                  <h3>
                    <input
                      type="date"
                      id="formAddDate2"
                      defaultValue={props.content.expirationDate}
                      required
                    ></input>
                  </h3>
                  <button>
                    <img className="check" src={check} alt="check"></img>{" "}
                  </button>
                  <h3 onClick={() => setSelect(false)}>
                    <img className="returns" src={returns} alt="returns"></img>{" "}
                  </h3>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}

export default BidonForm;
