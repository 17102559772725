import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../context/userContext";
import edit from "../images/edit.ico";
import check from "../images/check-mark.ico";
import returns from "../images/x-mark.ico";
function SettingProfil() {
  const {
    role,
    email,
    vac,
    station,
    stationList,
    getStationList,
    getUser,
    setStation,
    setEmail,
    setRole,
    setVac,
    setEnginAvailable,
    refreshToken,
  } = useContext(userContext);
  const [select, setSelect] = useState(false);
  const [select2, setSelect2] = useState(false);
  const [select3, setSelect3] = useState(false);
  async function updateProfil(e) {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BAC_URL}/user/postprofil`;
    const token = localStorage.getItem("XSRF-TOKEN");

    const availableTank = {
      email: e.target.elements.settingEmail.value,
      station: e.target.elements.settingStation.value,
      vac: e.target.elements.settingVac.value,
    };
    const data = JSON.stringify(availableTank);

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: Infos modifiées");
      getUser();
      setSelect(false);
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  async function deleteUser(e) {
    e.preventDefault();
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/user/deleteuser`;

    const deleteUser = {
      password: e.target.elements.settingPassword.value,
      email: email,
    };
    const data = JSON.stringify(deleteUser);
    const options = {
      method: "DELETE",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      localStorage.removeItem("XSRF-TOKEN");
      setEmail("");
      setRole("");
      setVac("");
      setEnginAvailable("");
      setStation("");
      alert("Succès: utilisateur supprimée");
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  async function updatePassword(e) {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BAC_URL}/user/changepassword`;
    const token = localStorage.getItem("XSRF-TOKEN");

    const upPassword = {
      oldPassword: e.target.elements.settingOldPassword.value,
      newPassword: e.target.elements.settingNewPassword.value,
    };
    const data = JSON.stringify(upPassword);

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Mot de passe modifiées");
      setSelect2(false);
      setSelect(false);
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  useEffect(() => {
    getStationList();
  }, [email]);
  if (email) {
    return (
      <div className="settings-form">
        <h3 className="settings-title">Paramètres</h3>

        {!select ? (
          <ul className="settings-info">
            <label htmlFor="email">Email: </label>
            <li type="text">{email}</li>
            <label htmlFor="station">station: </label>
            <li type="text">{station}</li>
            <label htmlFor="role">role: </label>
            <li type="text">{role}</li>
            <label htmlFor="vac">vac: </label>
            <li type="text">{vac}</li>
            <img
              className="edit"
              src={edit}
              alt="edit"
              onClick={() => setSelect(true)}
            ></img>{" "}
          </ul>
        ) : (
          <form className="settings-info" onSubmit={updateProfil}>
            <label htmlFor="email">Email: </label>
            <input
              id="settingEmail"
              type="text"
              defaultValue={email}
              required
              maxLength="40"
            />
            <label htmlFor="station">Etablissement: </label>
            {stationList ? (
              <select
                id="settingStation"
                type="text"
                defaultValue={station}
                required
              >
                {stationList.map((item, key) => (
                  <option key={key} value={item.name}>
                    {item.info}({item.name})
                  </option>
                ))}
              </select>
            ) : (
              <input disabled type="text" value={station} />
            )}
            <label htmlFor="role">Role: </label>
            <input disabled type="text" value={role} />
            <label htmlFor="vac">Vac: </label>
            <input id="settingVac" type="text" defaultValue={vac} />
            <li style={{ display: "flex", marginLeft: 100 }}>
              <button style={{ background: "none" }}>
                <img
                  type="submit"
                  className="edit"
                  src={check}
                  alt="edit"
                ></img>{" "}
              </button>
              <img
                className="edit"
                src={returns}
                alt="edit"
                onClick={() => setSelect(false)}
              ></img>{" "}
            </li>
          </form>
        )}
        {!select2 && !select3 ? (
          <div className="settings-btn">
            <button type="submit" onClick={() => setSelect2(true)}>
              Modifier MDP
            </button>
            <button
              type="submit"
              onClick={() => setSelect3(true)}
              style={{ backgroundColor: "red" }}
            >
              Supprimer compte
            </button>
          </div>
        ) : (
          <></>
        )}
        {!select2 ? (
          <></>
        ) : (
          <form className="settings-change-password" onSubmit={updatePassword}>
            <label htmlFor="password">Ancien mots de passe: </label>
            <input
              id="settingOldPassword"
              type="text"
              maxLength="40"
              required
            />
            <label htmlFor="password">Nouveau mots de passe: </label>
            <input
              id="settingNewPassword"
              type="text"
              maxLength="40"
              required
            />
            <label htmlFor="password">Confirmez nouveau mots de passe: </label>
            <input
              id="settingConfirmPassword"
              type="text"
              maxLength="40"
              required
            />
            <li style={{ display: "flex", marginLeft: 100 }}>
              <button style={{ background: "none" }}>
                <img
                  type="submit"
                  className="edit"
                  src={check}
                  alt="edit"
                ></img>{" "}
              </button>
              <img
                className="edit"
                src={returns}
                alt="edit"
                onClick={() => setSelect2(false)}
              ></img>{" "}
            </li>
          </form>
        )}
        {!select3 ? (
          <></>
        ) : (
          <form className="settings-change-password" onSubmit={deleteUser}>
            <label htmlFor="password">
              Rentrez votre mots de passe pour confirmer la suppression votre
              compte{" "}
            </label>

            <input id="settingPassword" type="text" maxLength="40" required />
            <li style={{ display: "flex", marginLeft: 100 }}>
              <button style={{ background: "none" }}>
                <img
                  type="submit"
                  className="edit"
                  src={check}
                  alt="edit"
                ></img>{" "}
              </button>
              <img
                className="edit"
                src={returns}
                alt="edit"
                onClick={() => setSelect3(false)}
              ></img>{" "}
            </li>
          </form>
        )}
      </div>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Veuillez vous reconecter</h3>{" "}
      </div>
    );
  }
}

export default SettingProfil;
