import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import logo from "../images/logo.png";
import { userContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
function NavBar() {
  const {
    email,
    setConnect,
    setEmail,
    setRole,
    setVac,
    setEnginAvailable,
    setStation,
    role,
    station,
  } = useContext(userContext);
  const [isMenu, setisMenu] = useState(false);
  const [logoStation, setLogoStation] = useState([]);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const navigate = useNavigate();
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }
  async function logOut() {
    const url = `${process.env.REACT_APP_BAC_URL}/user/logout`;
    const token = localStorage.getItem("XSRF-TOKEN");
    const options = {
      method: "POST",
      headers: { Authorization: "bearer " + token },
      credentials: "include",
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      localStorage.removeItem("XSRF-TOKEN");
      setEmail("");
      setRole("");
      setVac("");
      setEnginAvailable("");
      setStation("");
      setConnect(false);
      setLogoStation("");
      getStationInfo();

      navigate("/connexion");
    } else {
      alert("Une erreure est survenue");
    }
  }
  async function getStationInfo() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/getLogoStation/${station}`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setLogoStation(result.data);
    }
  }
  useEffect(() => {
    getStationInfo();
  }, [station]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <img className="logo" src={logo} alt="logo" />{" "}
        {logoStation && logoStation[0] ? (
          <img
            className="logo-sncf"
            src={`${process.env.REACT_APP_BAC_URL}/images/stationLogo/${logoStation[0].logo}`}
            alt="sncf"
          />
        ) : (
          <></>
        )}
      </div>
      <header className="header__middle">
        <div className="container">
          <div className="row">
            <div className="header__middle__menus">
              <nav className="main-nav ">
                {/* Responsive Menu Button */}
                {logoStation && logoStation[0] ? (
                  <img
                    className="logo-sncf-mobile"
                    src={`${process.env.REACT_APP_BAC_URL}/images/stationLogo/${logoStation[0].logo}`}
                    alt="sncf"
                  />
                ) : (
                  <></>
                )}
                <img className="logo-mobile" src={logo} alt="logo" />{" "}
                {isResponsiveclose === true ? (
                  <>
                    {/* Add Logo  */}
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      {" "}
                      <FiXCircle />{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      {" "}
                      <FiAlignRight />{" "}
                    </span>
                  </>
                )}
                <ul className={boxClass.join(" ")}>
                  <li className="menu-item">
                    <NavLink
                      activeclassname="is-active"
                      onClick={toggleClass}
                      to={`/`}
                    >
                      {" "}
                      Accueil{" "}
                    </NavLink>
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/info`}
                    >
                      {" "}
                      Info{" "}
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/contact`}
                    >
                      {" "}
                      Contact{" "}
                    </NavLink>{" "}
                  </li>

                  {!email ? (
                    <ul>
                      <li
                        onClick={toggleSubmenu}
                        className="menu-item sub__menus__arrows"
                      >
                        {" "}
                        <Link to="#">
                          {" "}
                          Connexion <FiChevronDown />{" "}
                        </Link>
                        <ul className={boxClassSubMenu.join(" ")}>
                          <li>
                            {" "}
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/connexion`}
                            >
                              {" "}
                              Connexion{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/inscription`}
                            >
                              {" "}
                              Inscription{" "}
                            </NavLink>{" "}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {role === "admin" ? (
                    <ul>
                      <li
                        onClick={toggleSubmenu}
                        className="menu-item sub__menus__arrows"
                      >
                        {" "}
                        <Link to="#">
                          {" "}
                          Menu <FiChevronDown />{" "}
                        </Link>
                        <ul className={boxClassSubMenu.join(" ")}>
                          <li>
                            {" "}
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/consultationBidon`}
                            >
                              {" "}
                              Fûts{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/gestionEngin`}
                            >
                              {" "}
                              Engins{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/availiblecapteurgestion`}
                            >
                              {" "}
                              Gérer capteurs{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/fluidtypegestion`}
                            >
                              {" "}
                              type de Fluid{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/engintypegestion`}
                            >
                              {" "}
                              Type d'engins{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/usersgestion`}
                            >
                              {" "}
                              Effectifs{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/stationgestion`}
                            >
                              {" "}
                              Station{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/message`}
                            >
                              {" "}
                              Tickets{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/setting`}
                            >
                              {" "}
                              Paramétres{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={() => logOut()}
                              className="btn-logout"
                              activeclassname="is-active"
                              to={`/connexion`}
                            >
                              {" "}
                              Déconnexion{" "}
                            </NavLink>{" "}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {email && role !== "admin" && role !== "" ? (
                    <ul>
                      <li
                        onClick={toggleSubmenu}
                        className="menu-item sub__menus__arrows"
                      >
                        {" "}
                        <Link to="#">
                          {" "}
                          Menu <FiChevronDown />{" "}
                        </Link>
                        <ul className={boxClassSubMenu.join(" ")}>
                          {role === "teamLeader" ? (
                            <li>
                              <NavLink
                                onClick={toggleClass}
                                activeclassname="is-active"
                                to={`/gestionEngin`}
                              >
                                {" "}
                                Engins{" "}
                              </NavLink>{" "}
                            </li>
                          ) : (
                            <></>
                          )}
                          <li>
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/setting`}
                            >
                              {" "}
                              Paramétres{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            {" "}
                            <NavLink
                              onClick={toggleClass}
                              activeclassname="is-active"
                              to={`/consultationBidon`}
                            >
                              {" "}
                              Fûts{" "}
                            </NavLink>{" "}
                          </li>
                          <li>
                            <NavLink
                              onClick={() => logOut()}
                              className="btn-logout"
                              activeclassname="is-active"
                              to={`/connexion`}
                            >
                              {" "}
                              Déconnexion{" "}
                            </NavLink>{" "}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default NavBar;
