import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import edit from "../images/edit.ico";
import mark from "../images/x-mark.ico";
import darkInfo from "../images/info.png";
import ImageVisual from "./ImageVisual";

function OtherEnginChildren(props) {
  const navigate = useNavigate();
  const [fluidInfo, setFluidInfo] = useState([]);
  const [select, setSelect] = useState(false);
  const [select2, setSelect2] = useState(false);
  async function getFluidInfo() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/getfluidimage/${props.content.oilType}`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setFluidInfo(result.data);
    }
  }
  useEffect(() => {
    getFluidInfo();
  }, []);

  return (
    <>
      {select ? (
        <>
          <ImageVisual
            imageName={`Image: ${props.content.tankType} ${props.content.oilType}`}
            imageUrl={`${process.env.REACT_APP_BAC_URL}/images/fluid/${fluidInfo[0].image}`}
          />
          <img
            onClick={() => setSelect(false)}
            className="mark-image"
            src={mark}
            alt="boutton-return"
          ></img>
        </>
      ) : (
        <></>
      )}
      {select2 ? (
        <>
          <ImageVisual
            imageName={`Fiche produit: ${props.content.tankType} ${props.content.oilType}`}
            imageUrl={`${process.env.REACT_APP_BAC_URL}/images/fluid/${fluidInfo[0].fds}`}
          />
          <img
            onClick={() => setSelect2(false)}
            className="mark-image"
            src={mark}
            alt="boutton-return"
          ></img>
        </>
      ) : (
        <div className="other-engin-card">
          <h4
            style={{
              position: "relative",
              marginTop: 20,
              textAlign: "center",
            }}
          >
            {props.content.oilType}
            <img
              className="info"
              src={darkInfo}
              alt="boutton-info"
              onClick={() => setSelect2(true)}
            ></img>
          </h4>
          <h4
            style={{
              position: "relative",

              textAlign: "center",
            }}
          >
            Modifier
            <img
              className="edit"
              src={edit}
              alt="edit"
              onClick={() => navigate("/consultationBidon")}
            ></img>{" "}
          </h4>
          {fluidInfo && fluidInfo[0] ? (
            <img
              style={{ height: 200, paddingTop: 30 }}
              className="axle"
              src={`${process.env.REACT_APP_BAC_URL}/images/fluid/${fluidInfo[0].image}`}
              alt="axle"
              onClick={() => setSelect(true)}
            ></img>
          ) : (
            <img
              style={{ height: 200, paddingTop: 30 }}
              className="axle"
              src={`${process.env.REACT_APP_BAC_URL}/images/fluid/defaultFluid.jpg`}
              alt="axle"
            ></img>
          )}
          <h5
            style={{
              position: "relative",

              textAlign: "center",
            }}
          >
            Date d'expiration: {props.content.expirationDate}
          </h5>
        </div>
      )}
    </>
  );
}

export default OtherEnginChildren;
