import React, { useEffect, useState, useContext } from "react";
import { userContext } from "../context/userContext";

import trash from "../images/trash.ico";
import edit from "../images/edit.ico";
import check from "../images/check-mark.ico";
import returns from "../images/x-mark.ico";
function UsersGestion() {
  const { role, stationList, getStationList, refreshToken } =
    useContext(userContext);
  const [usersList, setUsersList] = useState();
  const [search, setSearch] = useState();
  const [select, setSelect] = useState(false);

  async function getUsersList() {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/user/getusers`;

      const options = {
        method: "GET",
        headers: {
          Authorization: "bearer " + token,
        },
        credentials: "include",
      };
      const response = await fetch(url, options);

      let result = await response.json();
      if (response.status === 200) {
        setUsersList(result.data);
      }
      if (response.status === 401) {
        refreshToken();
      }
    } else {
      return;
    }
  }
  async function modifyUser(event) {
    event.preventDefault();
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/user/modifieuseradmin`;

    const userInfo = {
      role: event.target.elements.modifyRole.value,
      email: event.target.elements.modifyEmail.value,
      station: event.target.elements.modifyStation.value,
      active: event.target.elements.modifyActive.value,
    };
    const data = JSON.stringify(userInfo);
    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: utilisateur modfifié avec succés");
      getUsersList();
      setSelect(false);
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  async function deleteUser(id) {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/user/deleteuseradmin`;
    console.log(id);
    const messageId = {
      userId: id,
    };
    const data = JSON.stringify(messageId);
    const options = {
      method: "DELETE",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: utilisateur supprimée");
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  useEffect(() => {
    getUsersList();
    getStationList();
  }, [role]);
  if (role === "admin") {
    if (usersList) {
      const newUsersList = usersList.filter((userList) => {
        if (search === "") return usersList;
        return (
          userList.email.toLowerCase().includes(search) ||
          userList.role.toLowerCase().includes(search) ||
          userList.date.toLowerCase().includes(search) ||
          userList.station.toLowerCase().includes(search)
        );
      });
      return (
        <>
          <div className="engin-array">
            <div className="capteur-title-container">
              <input
                id="search"
                type="text"
                placeholder="Recherche"
                style={{ width: 300, margin: "0 auto" }}
                onChange={(event) => setSearch(event.target.value)}
                value={search}
              ></input>
            </div>
          </div>
          {newUsersList && search ? (
            <>
              {newUsersList.map((item, key) => (
                <div className="message-array-info " key={key}>
                  <div className="message-content-container">
                    {!select ? (
                      <>
                        <ul>
                          <li>
                            <p>Email:</p> {item.email}
                          </li>
                          <li>
                            <p>Role:</p>
                            {item.role}
                          </li>
                          <li>
                            <p>Etablissement:</p>
                            {item.station}
                          </li>
                          <li>
                            <p>actif:</p>
                            {item.active}
                          </li>
                          <li style={{ paddingRight: 100 }}>
                            <p>Date:</p>
                            {item.date.substring(3, 15)}
                          </li>
                        </ul>
                        <img
                          className="edit"
                          style={{ marginRight: 50 }}
                          src={edit}
                          alt="edit"
                          onClick={() => setSelect(true)}
                        ></img>{" "}
                        <img
                          className="trash"
                          src={trash}
                          alt="trash"
                          onClick={() => deleteUser(item._id)}
                        ></img>{" "}
                      </>
                    ) : (
                      <form onSubmit={modifyUser(item._id)}>
                        <ul>
                          <li>
                            <p>Email:</p>
                            <input
                              id="modifyEmail"
                              type="texte"
                              defaultValue={item.email}
                              maxLength="40"
                              required
                            />
                          </li>
                          <li>
                            <p>Role:</p>
                            <select
                              id="modifyRole"
                              defaultValue={item.role}
                              required
                            >
                              <option value="public">Public</option>
                              <option value="agent">Agent</option>
                              <option value="teamLeader">Team-Leader</option>
                              <option value="admin">Admin</option>
                            </select>
                          </li>
                          <li>
                            <p>Etablissement:</p>
                            <select
                              id="modifyStation"
                              defaultValue={item.station}
                              required
                            >
                              {stationList.map((item, key) => (
                                <>
                                  <option key={key} value={item.name}>
                                    {item.name}
                                  </option>
                                </>
                              ))}
                            </select>
                          </li>
                          <li>
                            <p>Actif:</p>
                            <select
                              id="modifyActive"
                              defaultValue={item.active}
                              required
                            >
                              <option value="true">true</option>
                              <option value="false">false</option>
                              <option value="ban">ban</option>
                            </select>
                          </li>

                          <li style={{ paddingRight: 50 }}>
                            <p>Date:</p>
                            {item.date.substring(3, 15)}
                          </li>
                        </ul>
                        <button>
                          <img
                            className="check"
                            style={{ marginRight: 50 }}
                            src={check}
                            alt="check"
                          ></img>{" "}
                        </button>
                        <img
                          className="returns"
                          src={returns}
                          alt="returns"
                          onClick={() => setSelect(false)}
                        ></img>{" "}
                      </form>
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {usersList.map((item, key) => (
                <div className="message-array-info " key={key}>
                  <div className="message-content-container">
                    {!select ? (
                      <>
                        <ul>
                          <li>
                            <p>Email:</p> {item.email}
                          </li>
                          <li>
                            <p>Role:</p>
                            {item.role}
                          </li>
                          <li>
                            <p>Etablissement:</p>
                            {item.station}
                          </li>
                          <li>
                            <p>actif:</p>
                            {item.active}
                          </li>
                          <li style={{ paddingRight: 100 }}>
                            <p>Date:</p>
                            {item.date.substring(3, 15)}
                          </li>
                        </ul>
                        <img
                          className="edit"
                          style={{ marginRight: 50 }}
                          src={edit}
                          alt="edit"
                          onClick={() => setSelect(true)}
                        ></img>{" "}
                        <img
                          className="trash"
                          src={trash}
                          alt="trash"
                          onClick={() => deleteUser(item._id)}
                        ></img>{" "}
                      </>
                    ) : (
                      <form onSubmit={modifyUser}>
                        <ul>
                          <li>
                            <p>Email:</p>
                            <input
                              id="modifyEmail"
                              type="texte"
                              defaultValue={item.email}
                              maxLength="50"
                              required
                            />
                          </li>
                          <li>
                            <p>Role:</p>
                            <select
                              id="modifyRole"
                              defaultValue={item.role}
                              required
                            >
                              <option value="public">Public</option>
                              <option value="agent">Agent</option>
                              <option value="teamLeader">Team-Leader</option>
                              <option value="admin">Admin</option>
                            </select>
                          </li>
                          <li>
                            <p>Etablissement:</p>
                            <select
                              id="modifyStation"
                              defaultValue={item.station}
                              required
                            >
                              {stationList.map((item, key) => (
                                <>
                                  <option key={key} value={item.name}>
                                    {item.name}
                                  </option>
                                </>
                              ))}
                            </select>
                          </li>
                          <li>
                            <p>Actif:</p>
                            <select
                              id="modifyActive"
                              defaultValue={item.active}
                              required
                            >
                              <option value="true">true</option>
                              <option value="false">false</option>
                              <option value="ban">ban</option>
                            </select>
                          </li>
                          <li style={{ paddingRight: 50 }}>
                            <p>Date:</p>
                            {item.date.substring(3, 15)}
                          </li>
                        </ul>
                        <button>
                          <img
                            className="check"
                            style={{ marginRight: 50 }}
                            src={check}
                            alt="check"
                          ></img>{" "}
                        </button>
                        <img
                          className="returns"
                          src={returns}
                          alt="returns"
                          onClick={() => setSelect(false)}
                        ></img>{" "}
                      </form>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      );
    }
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}
export default UsersGestion;
