import React from "react";
import { useNavigate } from "react-router-dom";
import plus from "../images/plus.ico";
import edit from "../images/edit.ico";
import info from "../images/info.ico";

import OtherEnginChildren from "./OtherEnginChildren";

function OtherEngin(props) {
  const navigate = useNavigate();

  if (props.content.idCapteur === "") {
    return (
      <div className="engin-card-container">
        <h3 className="title-other-engin">
          {props.content.typeEngin} {props.content.idEngin}
          <img className="info" src={info} alt="boutton-info"></img>
          <img
            className="edit"
            src={edit}
            alt="edit"
            onClick={() => navigate("/gestionEngin")}
          ></img>{" "}
        </h3>
        <div className="other-engin">
          {props.content.tank.length >= 1 ? (
            <>
              {props.content.tank.map((item, key) => (
                <OtherEnginChildren content={item} key={key} />
              ))}
            </>
          ) : (
            <div className="other-engin-card">
              <h4>Oups pas encore renseigné</h4>
              <h5>Pour ajouter un bidon ou un pot de graisse</h5>
              <h5>veuillez cliquer ici</h5>
              <img
                onClick={() => navigate("/consultationBidon")}
                className="plus"
                src={plus}
                alt="plus"
                style={{ paddingLeft: 20 }}
              ></img>{" "}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OtherEngin;
