import React, { useState, useContext, useEffect } from "react";
import trash from "../images/trash.ico";

import check from "../images/check-mark.ico";

import add from "../images/plus.ico";
import returns from "../images/x-mark.ico";
import soon from "../images/soon.jpg";
import { userContext } from "../context/userContext";
function AddCapteurAvailible() {
  const {
    role,
    getStationList,
    stationList,
    refreshToken,
    getAvailableCapteur,
    availableCapteur,
  } = useContext(userContext);
  const [select, setSelect] = useState();

  async function addNewCapteurAvailible(event) {
    event.preventDefault();
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/addnewcapteuravailible`;
      const station = {
        idCapteur: event.target.elements.idCapteur.value,
        type: event.target.elements.capteurType.value,
        authorization: event.target.elements.authorisation.value,
        station: event.target.elements.modifyStation.value,
      };
      const data = JSON.stringify(station);
      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: data,
      };

      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Ajout d'un nouveau capteur réussi");
        setSelect(false);
        getAvailableCapteur();
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue");
      }
    } else {
      return;
    }
  }
  async function deleteCapteurAvailible(id) {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/deleteavailiblecapteur`;

      const capteurAvailibleId = {
        capteurId: id,
      };
      const data = JSON.stringify(capteurAvailibleId);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: data,
      };
      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Succès: capteur supprimée");
        getAvailableCapteur();
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    getStationList();
    getAvailableCapteur();
  }, [role]);
  if (role === "admin") {
    return (
      <>
        <div className="engin-array">
          <div className="capteur-title-container">
            <h3>Gestion des capteurs</h3>
          </div>
        </div>
        <>
          {availableCapteur ? (
            <>
              {availableCapteur.map((item, key) => (
                <div className="message-array-info " key={key}>
                  <div className="message-content-container">
                    <ul>
                      <li>
                        <p>Numéro:</p> {item.idCapteur}
                      </li>
                      <li>
                        <p>type:</p>
                        {item.type}
                      </li>
                      <li style={{ paddingRight: 200 }}>
                        <p>Numéro d'autorisation:</p>
                        {item.authorization}
                      </li>
                      <li>
                        <p>Etablissement</p>
                        {item.station}
                      </li>
                      <li>
                        <p>Date:</p>
                        {item.date.substring(3, 15)}
                      </li>
                    </ul>
                    <img
                      className="trash"
                      src={trash}
                      alt="trash"
                      onClick={() => deleteCapteurAvailible(item._id)}
                    ></img>{" "}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
          {!select ? (
            <div className="btn-add-engin">
              <img
                className="add"
                src={add}
                alt="add"
                onClick={() => setSelect(true)}
              ></img>
            </div>
          ) : (
            <form
              className="message-array-info"
              onSubmit={addNewCapteurAvailible}
            >
              <div className="message-content-container">
                <ul>
                  <li>
                    <p>Numéro:</p>
                    <input
                      id="idCapteur"
                      type="texte"
                      placeholder="12345..."
                      maxLength="20"
                      required
                    />
                  </li>
                  <li>
                    <p>Type:</p>
                    <input
                      id="capteurType"
                      type="texte"
                      placeholder="Niveau,température...."
                      maxLength="50"
                      required
                    />
                  </li>
                  <li style={{ paddingRight: 50 }}>
                    <p>Numéro d'autorisation:</p>
                    <input
                      id="authorisation"
                      type="texte"
                      placeholder="f51r65ezf..."
                      maxLength="50"
                      required
                    />
                  </li>
                  <li style={{ paddingRight: 150 }}>
                    <p>Etablissement:</p>
                    <select
                      id="modifyStation"
                      //defaultValue={item.station}
                      required
                    >
                      {stationList.map((item, key) => (
                        <>
                          <option key={key} value={item.name}>
                            {item.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </li>
                </ul>
                <button>
                  <img
                    className="check"
                    src={check}
                    alt="check"
                    style={{ top: 0, right: 100 }}
                  ></img>{" "}
                </button>
                <img
                  className="returns"
                  src={returns}
                  alt="returns"
                  onClick={() => setSelect(false)}
                  style={{ top: 0 }}
                ></img>{" "}
              </div>
            </form>
          )}
        </>
      </>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}

export default AddCapteurAvailible;
