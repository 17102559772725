import { createContext, useState, useEffect } from "react";

export const userContext = createContext(
  { email: "", setEmail: () => {} },
  { role: "", setRole: () => {} },
  { vac: "", setVac: () => {} },
  { station: "", setStation: () => {} },
  { enginAvailable: [], setEnginAvailable: () => {} },
  { stationList: [], setStationList: () => {} },
  { fluidList: "", setFluidList: () => {} },
  { enginTypeList: "", setEnginTypeList: () => {} },
  { availableCapteur: "", setAvailableCapteur: () => {} }
);

export default function UserProvider(props) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [vac, setVac] = useState("");
  const [station, setStation] = useState("");
  const [enginAvailable, setEnginAvailable] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [fluidList, setFluidList] = useState([]);
  const [enginTypeList, setEnginTypeList] = useState([]);
  const [availableCapteur, setAvailableCapteur] = useState([]);

  async function getStationList() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/station`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setStationList(result.data);
    }
  }
  async function getFluidList() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/getfluid/${station}`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setFluidList(result.data);
    }
    if (response.status === 401) {
      refreshToken();
    }
  }
  async function getEnginTypeList() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/getengintypelist/${station}`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setEnginTypeList(result.data);
    }
    if (response.status === 401) {
      refreshToken();
    }
  }
  async function getAvailableCapteur() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/getcapteuravailablelist/${station}`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setAvailableCapteur(result.data);
    }
    if (response.status === 401) {
      refreshToken();
    }
  }

  async function getEnginAvailable(station) {
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/enginamount/${station}`;
    const token = localStorage.getItem("XSRF-TOKEN");

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();

    setEnginAvailable(result.ActifEngin);
  }

  async function getUser() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/user/profil`;

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();

    if (response.status === 200) {
      setStation(result.profil.station);
      setEmail(result.profil.email);
      setRole(result.profil.role);
      setVac(result.profil.vac);

      getEnginAvailable(result.profil.station);
    }
    if (response.status === 401) {
      refreshToken();
    }
  }

  async function refreshToken() {
    const url = `${process.env.REACT_APP_BAC_URL}/user/refresh`;
    const options = {
      method: "POST",
      headers: {},
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();

    if (response.status === 200) {
      localStorage.setItem("XSRF-TOKEN", result.xsrfToken);
      getUser();
    } else if (response.status === 401) {
      setEmail("");
      setRole("");
    }
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <userContext.Provider
      value={{
        email,
        setEmail,
        role,
        setRole,
        station,
        setStation,
        vac,
        setVac,
        stationList,
        setStationList,
        enginAvailable,
        setEnginAvailable,
        fluidList,
        setFluidList,
        enginTypeList,
        setEnginTypeList,
        availableCapteur,
        setAvailableCapteur,
        getEnginAvailable,
        getStationList,
        getUser,
        refreshToken,
        getFluidList,
        getEnginTypeList,
        getAvailableCapteur,
      }}
    >
      {props.children}{" "}
    </userContext.Provider>
  );
}
