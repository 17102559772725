import React from "react";

function Info() {
  return (
    <div>
      {" "}
      <h1>Coming soon....</h1>
      <h3>Page Info en dev</h3>
    </div>
  );
}

export default Info;
