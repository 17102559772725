import React from "react";
import passwordEye from "../images/eye.ico";
import { useNavigate, Link } from "react-router-dom";
import { useContext, useState } from "react";
import { userContext } from "../context/userContext";

function SignIn() {
  const navigate = useNavigate();
  const { setEmail, getUser } = useContext(userContext);
  const [validateEmail, setValidateEmail] = useState("");
  const [emailSpan, setEmailSpan] = useState("");
  const [connectionSpan, setConnectionSpan] = useState("");
  const [passWordType, setPasswordType] = useState("password");

  const emailChecker = (value) => {
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailSpan("L'adresse mail n'est pas valide");
      setValidateEmail(null);
    } else {
      setEmailSpan("");
      setValidateEmail(value);
    }
  };
  async function handleSubmit(event) {
    event.preventDefault();

    if (validateEmail) {
      const url = `${process.env.REACT_APP_BAC_URL}/user/signin`;

      const userInfo = {
        password: event.target.elements.password.value,
        email: validateEmail,
      };
      const data = JSON.stringify(userInfo);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
        credentials: "include",
      });
      let result = await response.json();

      if (response.status === 200) {
        localStorage.setItem("XSRF-TOKEN", result.xsrfToken);
        getUser();
        setEmail(validateEmail);
        navigate("/connexionreussi");
      }
      if (response.status === 404) {
        setConnectionSpan("Email ou Mot De Passe incorrect");
      }
      if (response.status === 402) {
        setConnectionSpan("Vous avez été bannis, veuillez contacter l'admin");
      }
    }
  }
  return (
    <>
      <h3 className="contact-title">Connexion</h3>
      <form onSubmit={handleSubmit} className="signin-form">
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            autoComplete="off"
            id="email"
            maxLength="50"
            onChange={(e) => emailChecker(e.target.value)}
          />
          <span style={{ color: "red" }}>{emailSpan}</span>
        </div>

        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <label htmlFor="password">Mot de passe</label>
          <input
            type={passWordType}
            autoComplete="off"
            id="password"
            maxLength="50"
          />
          {passWordType === "password" ? (
            <img
              className="passwordEye"
              src={passwordEye}
              alt="bottom show password"
              onClick={() => setPasswordType("text")}
            ></img>
          ) : (
            <img
              className="passwordEye"
              src={passwordEye}
              alt="bottom show password"
              onClick={() => setPasswordType("password")}
            ></img>
          )}
          <Link style={{ textDecoration: "underline" }} to={"/forgetpassword"}>
            Mot de passe oublié?
          </Link>
          <Link
            style={{ float: "right", textDecoration: "underline" }}
            to={"/inscription"}
          >
            S'inscrire
          </Link>
          <span className="connection-span">{connectionSpan}</span>
        </div>
        <input type="submit" value="Se connecter" />
      </form>
    </>
  );
}

export default SignIn;
