import React, { useState, useContext, useEffect } from "react";
import check from "../images/check-mark.ico";
import add from "../images/plus.ico";
import returns from "../images/x-mark.ico";
import { userContext } from "../context/userContext";
import AddEnginTypeChildren from "./AddEnginTypeChildren";
function AddEnginType() {
  const {
    role,
    stationList,
    getStationList,
    refreshToken,
    enginTypeList,
    getEnginTypeList,
  } = useContext(userContext);
  const [select, setSelect] = useState();
  const [userImage, setUserImage] = useState([]);
  const handleImage = (e) => {
    e.persist();
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setUserImage(img);
  };
  async function addNewEnginType(event) {
    event.preventDefault();
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/addengintype`;
      const formData = new FormData();
      formData.append("file", userImage.data);
      formData.append("type", event.target.elements.enginType.value);
      formData.append("station", event.target.elements.modifyStation.value);
      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
        },
        credentials: "include",
        body: formData,
      };
      const response = await fetch(url, options);
      if (response.status === 200) {
        alert("Ajout d'un nouveau type d'engin reussi");
        setSelect(false);
        getEnginTypeList();
        setUserImage("");
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue");
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    getStationList();
    getEnginTypeList();
  }, [role]);
  if (role === "admin") {
    return (
      <>
        <div className="engin-array">
          <div className="capteur-title-container">
            <h3>Gestion des types d'engin</h3>
          </div>
        </div>
        <>
          {enginTypeList ? (
            <>
              {enginTypeList.map((item, key) => (
                <AddEnginTypeChildren content={item} key={key} />
              ))}
            </>
          ) : (
            <></>
          )}
          {!select ? (
            <div className="btn-add-engin">
              <img
                className="add"
                src={add}
                alt="add"
                onClick={() => setSelect(true)}
              ></img>
            </div>
          ) : (
            <form className="message-array-info" onSubmit={addNewEnginType}>
              <div className="message-content-container">
                <ul>
                  <li>
                    <p>Type:</p>
                    <input
                      id="enginType"
                      type="texte"
                      placeholder="Engin meca, passerelle toit...."
                      maxLength="20"
                      required
                    />
                  </li>
                  <li>
                    <p>Etablissement:</p>
                    <select id="modifyStation" required>
                      {stationList.map((item, key) => (
                        <>
                          <option key={key} value={item.name}>
                            {item.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </li>
                  <li style={{ paddingRight: 200, width: 400 }}>
                    <p>Image:</p>
                    {userImage.preview ? (
                      <img
                        className="soon"
                        src={`${userImage.preview}`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    ) : (
                      <img
                        className="soon"
                        src={`${process.env.REACT_APP_BAC_URL}/images/engin/defaultEngin.jpg`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    )}
                    <input
                      type="file"
                      name="uimage"
                      onChange={handleImage}
                      className="form-control"
                      id="uploadBtn"
                      accept=".png, .jpg, .jpeg, .x-icon, .ico"
                    />
                  </li>
                </ul>
                <button>
                  <img
                    className="check"
                    src={check}
                    alt="check"
                    style={{ top: 0, right: 100 }}
                  ></img>{" "}
                </button>
                <img
                  className="returns"
                  src={returns}
                  alt="returns"
                  onClick={() => {
                    setSelect(false);
                    setUserImage("");
                  }}
                  style={{ top: 0 }}
                ></img>{" "}
              </div>
            </form>
          )}
        </>
      </>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}
export default AddEnginType;
