import React, { useState, useContext, useEffect } from "react";
import trash from "../images/trash.ico";
import check from "../images/check-mark.ico";
import edit from "../images/edit.ico";
import returns from "../images/x-mark.ico";
import { userContext } from "../context/userContext";
function AddEnginTypeChildren(props) {
  const { role, stationList, getStationList, refreshToken, getEnginTypeList } =
    useContext(userContext);
  const [select, setSelect] = useState();

  const [userImage, setUserImage] = useState([]);

  const handleImage = (e) => {
    e.persist();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setUserImage(img);
  };

  async function modifyEnginType(event) {
    event.preventDefault();
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/modifyengintype`;
      const formData = new FormData();
      formData.append("file", userImage.data);
      formData.append("type", event.target.elements.enginType.value);
      formData.append("station", event.target.elements.modifyStation.value);
      formData.append("enginTypeId", props.content._id);
      formData.append("lastImage", props.content.image);

      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
        },
        credentials: "include",
        body: formData,
      };

      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Ajout d'un nouveau type d'engin reussi");
        setSelect(false);
        getEnginTypeList();
        setUserImage("");
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue");
      }
    } else {
      return;
    }
  }
  async function deleteEnginType(id, image) {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/deleteengintype`;

      const enginId = {
        enginId: id,
        image: image,
      };
      const data = JSON.stringify(enginId);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: data,
      };
      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Succès: Type d'engin supprimée");
        getEnginTypeList();
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    getStationList();
    getEnginTypeList();
  }, [role]);
  return (
    <>
      {!select ? (
        <div className="message-array-info ">
          <div className="message-content-container">
            <ul>
              <li>
                <p>Type:</p> {props.content.type}
              </li>
              <li>
                <p>Etablissement:</p> {props.content.station}
              </li>
              <li style={{ paddingRight: 100 }}>
                <p>Image:</p>
                <img
                  className="soon"
                  src={`${process.env.REACT_APP_BAC_URL}/images/engin/${props.content.image}`}
                  alt="trash"
                  style={{ width: 60 }}
                ></img>{" "}
              </li>
            </ul>
            <img
              className="edit"
              src={edit}
              alt="edit"
              onClick={() => setSelect(true)}
            ></img>{" "}
            <img
              className="trash"
              src={trash}
              alt="trash"
              onClick={() =>
                deleteEnginType(props.content._id, props.content.image)
              }
            ></img>{" "}
          </div>
        </div>
      ) : (
        <form className="message-array-info" onSubmit={modifyEnginType}>
          <div className="message-content-container">
            <ul>
              <li>
                <p>Type:</p>
                <input
                  id="enginType"
                  type="texte"
                  placeholder="Engin meca, passerelle toit...."
                  maxLength="20"
                  required
                  defaultValue={props.content.type}
                />
              </li>
              <li>
                <p>Etablissement:</p>
                <select
                  id="modifyStation"
                  defaultValue={props.content.station}
                  required
                >
                  {stationList.map((item, key) => (
                    <>
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    </>
                  ))}
                </select>
              </li>
              <li style={{ paddingRight: 200, width: 400 }}>
                <p>Image:</p>
                {userImage.preview ? (
                  <img
                    className="soon"
                    src={`${userImage.preview}`}
                    alt="trash"
                    style={{ width: 100 }}
                  ></img>
                ) : (
                  <img
                    className="soon"
                    src={`${process.env.REACT_APP_BAC_URL}/images/engin/${props.content.image}`}
                    alt="trash"
                    style={{ width: 100 }}
                  ></img>
                )}
                <input
                  type="file"
                  name="uimage"
                  onChange={handleImage}
                  className="form-control"
                  id="uploadBtn"
                  accept=".png, .jpg, .jpeg, .x-icon, .ico"
                />
              </li>
            </ul>
            <button>
              <img
                className="check"
                src={check}
                alt="check"
                style={{ top: 0, right: 100 }}
              ></img>{" "}
            </button>
            <img
              className="returns"
              src={returns}
              alt="returns"
              onClick={() => {
                setSelect(false);
                setUserImage("");
              }}
              style={{ top: 0 }}
            ></img>{" "}
          </div>
        </form>
      )}
    </>
  );
}

export default AddEnginTypeChildren;
