import React from "react";
import { Routes, Route } from "react-router-dom";

import "./styles/index.scss";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Info from "./pages/Info";
import Contact from "./pages/Contact";
import Capteur from "./pages/Capteur";
import Footer from "./components/Footer";
import BidonChange from "./pages/BidonChange";

import MessageAdmin from "./pages/MessageAdmin";
import UsersGestion from "./pages/UsersGestion";
import Station from "./pages/Station";
import AddCapteurAvailable from "./pages/AddCapteurAvailable";
import AddEnginType from "./pages/AddEnginType";
import AddFluidType from "./pages/AddFluidType";
import NotFound from "./pages/NotFound";
import SettingProfil from "./pages/SettingProfil";
import ForgetPassword from "./components/ForgetPassword";
import SuccesForgetPassword from "./components/SuccesForgetPassword";
import PasswordReset from "./components/PasswordReset";
import Cgu from "./pages/cgu";
import ValidationSignUp from "./components/ValidationSignUp";
import ValidationSignIn from "./components/ValidationSignIn";
import CheckSignUpToken from "./components/CheckSignUpToken";

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/info" element={<Info />} />
        <Route path="/gestionEngin" element={<Capteur />} />
        <Route path="/inscription" element={<SignUp />} />
        <Route path="/inscriptionreussi" element={<ValidationSignUp />} />
        <Route path="/connexion" element={<SignIn />} />
        <Route path="/connexionreussi" element={<ValidationSignIn />} />
        <Route path="/consultationBidon" element={<BidonChange />} />
        <Route path="/message" element={<MessageAdmin />} />
        <Route path="/usersgestion" element={<UsersGestion />} />
        <Route path="/cgu" element={<Cgu />} />
        <Route path="/stationgestion" element={<Station />} />
        <Route path="/setting" element={<SettingProfil />} />
        <Route
          path="/availiblecapteurgestion"
          element={<AddCapteurAvailable />}
        />
        <Route path="/fluidtypegestion" element={<AddFluidType />} />
        <Route path="/engintypegestion" element={<AddEnginType />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/password-reset/*" element={<PasswordReset />} />
        <Route path="/validate-email/*" element={<CheckSignUpToken />} />
        <Route
          path="/succesforgetpassword"
          element={<SuccesForgetPassword />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
