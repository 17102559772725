function ProgressBar(props) {
  //récupération de la couleur , et du niveau dans les props
  const { bgcolor, completed, liter } = props;
  //Arrondissement des valeurs à l'unité
  const arroundLiter = Math.round(liter);
  const arroundPct = Math.round(completed);
  // création d'un composant css pour injécter le style dynamiquement et
  //modifier la taille et la couleur de la barre de progression
  const fillerStyles = {
    width: "100px",
    height: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    //affichage de la barre de progression a l'ecran
    <div className="progress-bar">
      <div className="oil-lvl" style={fillerStyles}>
        <span className="label-styles">{`${arroundPct}%\n(${arroundLiter}L)`}</span>
      </div>
    </div>
  );
}

export default ProgressBar;
