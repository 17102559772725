import React, { useContext } from "react";
import { userContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
function Contact() {
  const { email, role } = useContext(userContext);
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    let userInfo;
    const url = `${process.env.REACT_APP_BAC_URL}/user/postmessage`;
    if (email) {
      userInfo = {
        email: event.target.elements.email.value,
        objet: event.target.elements.objet.value,
        role: role,
        message: event.target.elements.message.value,
      };
    } else {
      userInfo = {
        email: event.target.elements.email.value,
        objet: event.target.elements.objet.value,
        role: "public",
        message: event.target.elements.message.value,
      };
    }
    const data = JSON.stringify(userInfo);

    const response = await fetch(url, {
      method: "POST", //ou POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (response.status === 200) {
      alert("message envoyé");
      navigate("/");
    } else {
      alert("Une erreur est survenue");
    }
  }

  return (
    <>
      <h3 className="contact-title">Formulaire de contact </h3>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="input-container">
          <label htmlFor="mail">Email de contact</label>

          {email ? (
            <input
              value={email}
              type="text"
              autoComplete="off"
              id="email"
              maxLength="25"
              disabled
            />
          ) : (
            <input type="text" autoComplete="off" id="email" maxLength="25" />
          )}
        </div>

        <div>
          <label htmlFor="Objet">Objet</label>
          <input type="text" autoComplete="off" id="objet" maxLength="100" />
        </div>
        <div>
          <label htmlFor="Message">Message:</label>
          <textarea
            type="text"
            autoComplete="off"
            id="message"
            maxLength="2000"
          />
        </div>

        <input
          type="submit"
          value="Envoyer"
          className="submit-message"
          style={{ marginTop: 240 }}
        />
      </form>
    </>
  );
}

export default Contact;
