import React, { useEffect, useState } from "react";
import EnginInfo from "../components/EnginInfo";

function Engin(props) {
  const [oilLvlEngin, setOilLvlEngin] = useState();
  const [oilTempEngin, setOilTempEngin] = useState();
  const [grbLvlEngin, setGrbLvlEngin] = useState();
  const [grbTempEngin, setGrbTempEngin] = useState();
  const [idEngin] = useState(props.content.idEngin);
  const [typeEngin] = useState(props.content.typeEngin);
  async function getInfo() {
    //recherche d'idCapteur dans les props si non présent la requette ne ce fait pas
    if (props.content.idCapteur !== "") {
      //récupération du token XSRF
      const token = localStorage.getItem("XSRF-TOKEN");
      //concaténation de la route + id capteur
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/getlvl/${props.content.idCapteur}`;
      //définition des options
      const options = {
        method: "GET",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
      };
      //envoi de la requette
      const response = await fetch(url, options);
      //conversion du resultat
      let result = await response.json();
      //si réponse valide envoie des valeurs reçues
      if (response.status === 200) {
        setOilLvlEngin(result.oilInfo.oilLvl);
        setGrbLvlEngin(result.oilInfo.grbLvl);
        setOilTempEngin(result.oilInfo.oilTemp);
        setGrbTempEngin(result.oilInfo.grbTemp);
      }
    }
  }
  useEffect(() => {
    getInfo();
  }, [idEngin]);
  setInterval(() => {
    getInfo();
  }, 200000);
  if (props.content.idCapteur !== "") {
    return (
      <div className="engin-container">
        <EnginInfo
          oilLvlEngin={oilLvlEngin}
          grbLvlEngin={grbLvlEngin}
          oilTempEngin={oilTempEngin}
          grbTempEngin={grbTempEngin}
          idEngin={idEngin}
          typeEngin={typeEngin}
          content={props.content}
        />{" "}
      </div>
    );
  }
}

export default Engin;
