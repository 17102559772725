import React from "react";
import { useNavigate } from "react-router-dom";

export default function SuccesForgetPassword(props) {
  const navigate = useNavigate();
  return (
    <>
      <h3 className="contact-title">Reinitialisation mot de passe</h3>
      <form className="signin-form">
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <p htmlFor="email">
            Envoi réussi, consultez votre boite mail et cliquez sur le lien
            reçu.
          </p>
        </div>

        <input type="submit" onClick={() => navigate("/")} value="fermer" />
      </form>
    </>
  );
}
