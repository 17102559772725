import React, { useState, useContext, useEffect } from "react";
import { userContext } from "../context/userContext";
import trash from "../images/trash.ico";
import edit from "../images/edit.ico";
import check from "../images/check-mark.ico";
import returns from "../images/x-mark.ico";
function EnginList(props) {
  const [select, setSelect] = useState(false);
  const {
    getEnginAvailable,
    station,
    role,
    refreshToken,
    getEnginTypeList,
    getAvailableCapteur,
    enginTypeList,
    availableCapteur,
  } = useContext(userContext);
  async function deleteEnginAvailable() {
    if (role === "admin" || role === "teamLeader") {
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/deleteconfig`;
      const token = localStorage.getItem("XSRF-TOKEN");
      const availableEngin = {
        enginAvailibleId: props.content._id,
        station: station,
      };
      const data = JSON.stringify(availableEngin);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: data,
      };
      const response = await fetch(url, options);
      console.log(response.status);

      if (response.status === 200) {
        alert("Succès: configuration supprimée");
        getEnginAvailable(station);
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    }
  }
  async function updateEnginAvailable(e) {
    if (role === "admin" || role === "teamLeader") {
      e.preventDefault();
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/updateEngin`;
      const token = localStorage.getItem("XSRF-TOKEN");
      const availableEngin = {
        enginAvailibleId: props.content._id,
        idEngin: e.target.elements.formAddEngin.value,
        typeEngin: e.target.elements.typeEnginSelect.value,
        idCapteur: e.target.elements.formAddCapteur.value,
        date: e.target.elements.formAddDate.value,
      };
      const data = JSON.stringify(availableEngin);
      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: data,
      };
      const response = await fetch(url, options);
      if (response.status === 200) {
        alert("Succès: configuration modifiée");
        getEnginAvailable(station);
        setSelect(false);
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    }
  }
  useEffect(() => {
    getEnginTypeList();
    getAvailableCapteur();
  }, [role]);
  return (
    <>
      {!select ? (
        <div className="engin-array-info ">
          <div className="capteur-content-container">
            <h3>{props.content.typeEngin}</h3>
            <h3>{props.content.idEngin}</h3>
            <h3>{props.content.idCapteur}</h3>
            <h3 className="engin-list-date">
              {props.content.date.substring(0, 16)}
            </h3>
            <h3 className="engin-list-date">
              <img
                className="edit"
                src={edit}
                alt="edit"
                onClick={() => setSelect(true)}
              ></img>{" "}
            </h3>
            <h3 className="engin-list-date">
              <img
                className="trash"
                src={trash}
                alt="trash"
                onClick={deleteEnginAvailable}
              ></img>{" "}
            </h3>
          </div>
        </div>
      ) : (
        <form className="engin-array-info" onSubmit={updateEnginAvailable}>
          <div className="capteur-content-container">
            <h3>
              <select
                id="typeEnginSelect"
                defaultValue={props.content.typeEngin}
                required
              >
                {enginTypeList.map((item, key) => (
                  <>
                    <option key={key} value={item.type}>
                      {item.type}
                    </option>
                  </>
                ))}
              </select>
            </h3>
            <h3>
              <input
                type=""
                defaultValue={props.content.idEngin}
                id="formAddEngin"
                maxLength="20"
                required
              ></input>
            </h3>
            <h3>
              <>
                <select
                  defaultValue={props.content.idCapteur}
                  id="formAddCapteur"
                >
                  <option></option>
                  {availableCapteur.map((item, key) => (
                    <option key={key} value={item.idCapteur}>
                      {item.idCapteur}
                    </option>
                  ))}
                </select>
              </>
            </h3>
            <h3 style={{ paddingLeft: 40 }}>
              <input
                type="date"
                defaultValue={props.content.date}
                id="formAddDate"
                required
              ></input>
            </h3>
            <button>
              <img className="check" src={check} alt="check"></img>{" "}
            </button>
            <h3 onClick={() => setSelect(false)}>
              <img className="returns" src={returns} alt="returns"></img>{" "}
            </h3>
          </div>
        </form>
      )}
    </>
  );
}

export default EnginList;
