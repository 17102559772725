import React from "react";
import { Link } from "react-router-dom";
import copyright from "../images/copyright.png";
function Footer() {
  return (
    <footer>
      <Link
        to="/cgu"
        style={{
          textDecoration: "underline",
          color: "white",
          marginRight: 5,
        }}
      >
        CGU
      </Link>
      <img className="copyright" src={copyright} alt="copyright"></img>{" "}
      <h5>2022/2023 Sébastien Robello</h5>
    </footer>
  );
}

export default Footer;
