import React, { useContext } from "react";
//import BidonList from "../components/BidonList";
import BidonList from "../components/BidonList";

import { userContext } from "../context/userContext";
function BidonChange() {
  const { enginAvailable, role } = useContext(userContext);
  // if (role === "agent" || role === "teamLeader" || role === "admin") {
  if (role === "admin" || role === "agent" || role === "teamLeader") {
    return (
      <div>
        {enginAvailable.map((item, key) => (
          <BidonList content={item} key={key} />
        ))}
      </div>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>
      </div>
    );
  }
}

export default BidonChange;
