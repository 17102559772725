import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import info from "../images/info.ico";
import edit from "../images/edit.ico";
import mark from "../images/x-mark.ico";
import fut from "../images/fut.png";
import ImageVisual from "./ImageVisual";
function EnginInfoChildren(props) {
  const navigate = useNavigate();
  const [fluidInfo, setFluidInfo] = useState([]);
  const [select, setSelect] = useState(false);
  const [select2, setSelect2] = useState(false);

  async function getFluidInfo() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/getfluidimage/${props.content.oilType}`;

    const options = {
      method: "GET",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();
    if (response.status === 200) {
      setFluidInfo(result.data);
    }
  }
  useEffect(() => {
    getFluidInfo();
  }, []);
  return (
    <>
      {select ? (
        <>
          <ImageVisual
            imageName={`Fiche produit: ${props.content.tankType} ${props.content.oilType}`}
            imageUrl={`${process.env.REACT_APP_BAC_URL}/images/fluid/${fluidInfo[0].fds}`}
          />
          <img
            onClick={() => setSelect(false)}
            className="mark-image"
            src={mark}
            alt="boutton-return"
          ></img>
        </>
      ) : (
        <></>
      )}
      {select2 ? (
        <>
          <ImageVisual
            imageName={`Image: ${props.content.tankType} ${props.content.oilType}`}
            imageUrl={`${process.env.REACT_APP_BAC_URL}/images/fluid/${fluidInfo[0].image}`}
          />
          <img
            onClick={() => setSelect2(false)}
            className="mark-image"
            src={mark}
            alt="boutton-return"
          ></img>
        </>
      ) : (
        <></>
      )}
      <div className="oil-grb-tank">
        <h4 className="title-oil-grb">
          {props.content.tankType}
          <img
            className="info"
            src={info}
            alt="boutton-info"
            onClick={() => setSelect(true)}
          ></img>
          <img
            className="edit"
            src={edit}
            alt="edit"
            onClick={() => navigate("/consultationBidon")}
          ></img>{" "}
        </h4>
        {fluidInfo && fluidInfo[0] ? (
          <img
            className="fut"
            src={`${process.env.REACT_APP_BAC_URL}/images/fluid/${fluidInfo[0].image}`}
            alt="fut"
            onClick={() => setSelect2(true)}
          ></img>
        ) : (
          <img
            className="fut"
            src={fut}
            alt="fut"
            onClick={() => setSelect2(true)}
          ></img>
        )}

        <h5>Type: {props.content.oilType}</h5>
        <h5>Ouverture:{props.content.layingDate}</h5>
        <h5>Validitée:{props.content.expirationDate}</h5>
        <h5>Equipe: {props.content.vac}</h5>
      </div>
    </>
  );
}

export default EnginInfoChildren;
