import React, { useState, useContext, useEffect } from "react";
import { userContext } from "../context/userContext";
import EnginList from "../components/EnginList";
import add from "../images/plus.ico";
import check from "../images/check-mark.ico";
import returns from "../images/x-mark.ico";
function Capteur() {
  const {
    enginAvailable,
    getEnginAvailable,
    station,
    role,
    refreshToken,
    enginTypeList,
    availableCapteur,
    getEnginTypeList,
    getAvailableCapteur,
  } = useContext(userContext);
  const [select, setSelect] = useState(false);
  async function addEnginAvailable(e) {
    e.preventDefault();
    if (role === "admin" || role === "teamLeader") {
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/newengin`;
      const token = localStorage.getItem("XSRF-TOKEN");
      const availableEngin = {
        idEngin: e.target.elements.formAddEngin.value,
        idCapteur: e.target.elements.formAddCapteur.value,
        typeEngin: e.target.elements.typeEnginSelect.value,
        date: e.target.elements.formAddDate.value,
        station: station,
      };
      const data = JSON.stringify(availableEngin);
      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: data,
      };
      const response = await fetch(url, options);
      if (response.status === 200) {
        alert("Succés: configuration enregistrée");
        getEnginAvailable(station);
        setSelect(false);
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    getEnginTypeList();
    getAvailableCapteur();
  }, [role]);
  if (role === "admin" || role === "teamLeader") {
    return (
      <div className="capteur-container">
        <div className="engin-array">
          <div className="capteur-title-container">
            <h3>Type</h3>
            <h3 style={{ paddingLeft: 20 }}>Identifiant</h3>
            <h3>Capteur N°</h3>
            <h3>Date pose</h3>
            <h3>Modifier</h3>
            <h3>Supprimer</h3>
          </div>
        </div>
        {enginAvailable.map((item, key) => (
          <EnginList content={item} key={key} />
        ))}
        {!select ? (
          <div className="btn-add-engin">
            <img
              className="add"
              src={add}
              alt="add"
              onClick={() => setSelect(true)}
            ></img>
          </div>
        ) : (
          <form className="engin-array-info" onSubmit={addEnginAvailable}>
            <div className="capteur-content-container">
              <h3>
                <select id="typeEnginSelect" required>
                  {enginTypeList.map((item, key) => (
                    <>
                      <option key={key} value={item.type}>
                        {item.type}
                      </option>
                    </>
                  ))}
                </select>
              </h3>
              <h3>
                <input
                  type=""
                  placeholder="N°"
                  id="formAddEngin"
                  maxLength="10"
                  required
                ></input>
              </h3>
              <h3>
                <select id="formAddCapteur">
                  <option></option>
                  {availableCapteur.map((item, key) => (
                    <>
                      <option key={key} value={item.idCapteur}>
                        {item.idCapteur}
                      </option>
                    </>
                  ))}
                </select>
              </h3>
              <h3 style={{ paddingLeft: 40 }}>
                <input type="date" id="formAddDate" required></input>
              </h3>
              <button>
                <img className="check" src={check} alt="check"></img>{" "}
              </button>
              <h3 onClick={() => setSelect(false)}>
                <img className="returns" src={returns} alt="returns"></img>{" "}
              </h3>
            </div>
          </form>
        )}
      </div>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}

export default Capteur;
