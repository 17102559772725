import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
export default function PasswordReset() {
  const navigate = useNavigate();
  const [token, setToken] = useState(false);

  const urlToken = useLocation();
  const [validateEmail, setValidateEmail] = useState("");
  const [emailSpan, setEmailSpan] = useState("");
  const emailChecker = (value) => {
    if (!value.match(/^[\w_-]+@[\w-]+\.[a-z]{2,4}$/i)) {
      setEmailSpan("L'adresse mail n'est pas valide");
      setValidateEmail(null);
    } else {
      setEmailSpan("");
      setValidateEmail(value);
    }
  };

  async function checkLink() {
    const url = `${process.env.REACT_APP_BAC_URL}/user/validate-email`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: urlToken.pathname.substring(16),
      },
    });

    if (response.status === 200) {
      setToken(true);
      emailChecker(response.data);
    } else {
      emailChecker(response.data);
      setToken(false);
    }
  }
  async function resendLink() {
    const url = `${process.env.REACT_APP_BAC_URL}/user/resendsignuptoken`;
    const data = validateEmail;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (response.status === 200) {
      setToken(true);
      emailChecker(response.data);
    } else {
      emailChecker(response.data);
      setToken(false);
      alert("Une erreur est survenue, veuillez réessayer ultérieurement");
    }
  }
  useEffect(() => {
    checkLink();
  }, []);

  if (token) {
    return (
      <>
        <h3 className="contact-title">
          Bonjour {validateEmail}, Inscription validée
        </h3>

        <form className="signin-form">
          <div style={{ paddingRight: 20, paddingLeft: 20 }}>
            <p htmlFor="email">
              La vérification de l'email est un succès.Cliquez ci-dessous pour
              vous connecter.
            </p>
          </div>

          <input type="submit" onClick={() => navigate("/")} value="fermer" />
        </form>
      </>
    );
  } else {
    return (
      <>
        <form className="signin-form">
          <div style={{ paddingRight: 20, paddingLeft: 20 }}></div>
          <p className="contact-title">
            Lien de vérification de l'adresse mail invalide. Cliquez ci-dessous
            pour en envoyer un nouveau
          </p>
          <input
            type="text"
            autoComplete="off"
            id="email"
            maxLength="50"
            value={validateEmail}
            onChange={(e) => emailChecker(e.target.value)}
          />
          <span style={{ color: "red" }}>{emailSpan}</span>
          <input
            type="submit"
            onClick={() => resendLink()}
            value="Envoyer"
            style={{ width: 150 }}
          />
        </form>
      </>
    );
  }
}
