import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";
import info from "../images/info.ico";
import plus from "../images/plus.ico";
import arrow from "../images/arrow.png";
import edit from "../images/edit.ico";
import EnginInfoChildren from "./EnginInfoChildren";
function EnginInfo(props) {
  const {
    oilLvlEngin,
    grbLvlEngin,
    oilTempEngin,
    grbTempEngin,
    idEngin,
    typeEngin,
  } = props;
  const [filteredOilLvl, setFilteredOilLvl] = useState();
  const [filteredGrbLvl, setFilteredGrbLvl] = useState();
  const [select, setSelect] = useState();
  const [bgColor, setBgColor] = useState("");
  const [grbBgColor, setGrbBgColor] = useState("");
  const navigate = useNavigate();
  //conversion des données capteurs
  //huile en litre et pourcentage

  useEffect(() => {
    let filterOilLvl;
    if (oilLvlEngin) {
      if (oilLvlEngin <= 20) {
        setFilteredOilLvl("100");
      }
      if (oilLvlEngin <= 85 && oilLvlEngin > 20) {
        filterOilLvl = (85 - oilLvlEngin) * 1.17;
        setFilteredOilLvl(filterOilLvl);
      }

      if (oilLvlEngin > 85) {
        setFilteredOilLvl("0");
      }
    }
  }, [oilLvlEngin]);
  //conversion des données capteurs
  //GRB en litre et pourcentage
  useEffect(() => {
    let filterGrbLvl;
    if (grbLvlEngin) {
      if (grbLvlEngin <= 20) {
        setFilteredGrbLvl("100");
      }
      if (grbLvlEngin <= 65 && grbLvlEngin > 20) {
        filterGrbLvl = (65 - grbLvlEngin) * 1.67;
        setFilteredGrbLvl(filterGrbLvl);
      }
      if (grbLvlEngin > 65) {
        setFilteredGrbLvl("0");
      }
    }
  }, [grbLvlEngin]);
  //changement de couleur dynamique
  //de la barre de progression
  useEffect(() => {
    if (filteredOilLvl) {
      if (filteredOilLvl >= 51 && filteredOilLvl <= 100) {
        setBgColor("#adff2f");
      }
      if (filteredOilLvl <= 50 && filteredOilLvl >= 21) {
        setBgColor("orange");
      }

      if (filteredOilLvl <= 20 && filteredOilLvl >= 0) {
        setBgColor("red");
      }
    }
  }, [filteredOilLvl]);
  useEffect(() => {
    if (filteredGrbLvl) {
      if (filteredGrbLvl >= 65 && filteredGrbLvl <= 100) {
        setGrbBgColor("#adff2f");
      }
      if (filteredGrbLvl <= 64 && filteredGrbLvl >= 26) {
        setGrbBgColor("orange");
      }

      if (filteredGrbLvl <= 25 && filteredGrbLvl >= 0) {
        setGrbBgColor("red");
      }
    }
  }, [filteredGrbLvl]);
  const oilLiter = filteredOilLvl * 2;
  const grbLiter = filteredGrbLvl * 0.8;
  return (
    <div className="engin">
      <h3 className="title-engin">
        {typeEngin} {idEngin}{" "}
        <img
          className="info"
          src={info}
          alt="boutton-info"
          onClick={() => setSelect(true)}
        ></img>
        <img
          className="edit"
          src={edit}
          alt="edit"
          onClick={() => navigate("/gestionEngin")}
        ></img>{" "}
      </h3>
      {!select ? (
        <>
          <div className="engin-1">
            <div className="oil-tank-1">
              <h4 className="title-oil">PM/RED({oilTempEngin}°)</h4>
              <ProgressBar
                bgcolor={bgColor}
                completed={filteredOilLvl}
                liter={oilLiter}
              />
            </div>

            <div className="grb-tank-1">
              <h4 className="title-grb">GRB({grbTempEngin}°)</h4>
              <ProgressBar
                bgcolor={grbBgColor}
                completed={filteredGrbLvl}
                liter={grbLiter}
              />
            </div>
          </div>
          <h5 className="date-releve">Dernier relevé: Le 21/04/2023 à 15H </h5>
        </>
      ) : (
        <>
          <div className="engin-1">
            {props.content.tank.length >= 1 ? (
              <>
                {props.content.tank.map((item, key) => (
                  <EnginInfoChildren content={item} key={key} />
                ))}
              </>
            ) : (
              <div className="other-engin-card">
                <h4>Oups pas encore renseigné</h4>
                <h5>Pour ajouter un bidon ou un pot de graisse</h5>
                <h5>veuillez cliquer ici</h5>
                <img
                  onClick={() => navigate("/consultationBidon")}
                  className="plus"
                  src={plus}
                  alt="plus"
                  style={{ paddingLeft: 20 }}
                ></img>{" "}
              </div>
            )}
          </div>

          <img
            onClick={() => setSelect(false)}
            className="arrow"
            src={arrow}
            alt="boutton-return"
          ></img>
        </>
      )}
    </div>
  );
}

export default EnginInfo;
