import React, { useState, useContext, useEffect } from "react";
import trash from "../images/trash.ico";

import check from "../images/check-mark.ico";
import soon from "../images/soon.jpg";
import add from "../images/plus.ico";
import returns from "../images/x-mark.ico";
import { userContext } from "../context/userContext";
import AddFluidTypeChildren from "./addFluidTypeChildren";

function AddFluid() {
  const {
    role,
    stationList,
    getStationList,
    refreshToken,
    station,
    fluidList,
    getFluidList,
  } = useContext(userContext);
  const [select, setSelect] = useState();
  const [userImage, setUserImage] = useState([]);
  const [userFds, setUserFds] = useState([]);

  const handleImage = (e) => {
    e.persist();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setUserImage(img);
  };
  const handleFds = (e) => {
    e.persist();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setUserFds(img);
  };
  async function addNewFluid(event) {
    event.preventDefault();
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/lvl/addfluid`;
      const formData = new FormData();
      formData.append("file", userImage.data);
      formData.append("tankType", event.target.elements.tankType.value);
      formData.append("oilType", event.target.elements.oilType.value);
      formData.append("station", event.target.elements.modifyStation.value);
      formData.append("fds", userFds.data);
      const options = {
        method: "POST",
        headers: {
          Authorization: "bearer " + token,
          // "Content-Type": "multipart/form-data; border=XXX",
        },
        credentials: "include",
        body: formData,
      };

      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Ajout d'un nouveau fluide reussi");
        setSelect(false);
        getFluidList();
        setUserImage("");
        setUserFds("");
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue");
      }
    } else {
      return;
    }
  }

  useEffect(() => {
    getStationList();
    getFluidList();
  }, [role]);
  if (role === "admin") {
    return (
      <>
        <div className="engin-array">
          <div className="capteur-title-container">
            <h3>Gestion des types de fluides</h3>
          </div>
        </div>
        <>
          {fluidList ? (
            <>
              {fluidList.map((item, key) => (
                <AddFluidTypeChildren content={item} key={key} />
              ))}
            </>
          ) : (
            <></>
          )}
          {!select ? (
            <div className="btn-add-engin">
              <img
                className="add"
                src={add}
                alt="add"
                onClick={() => setSelect(true)}
              ></img>
            </div>
          ) : (
            <form className="message-array-info" onSubmit={addNewFluid}>
              <div className="message-content-container">
                <ul>
                  <li>
                    <p>Type:</p>
                    <input
                      id="tankType"
                      type="texte"
                      placeholder="Bidon,fût..."
                      maxLength="20"
                      required
                    />
                  </li>
                  <li>
                    <p>Désignation:</p>
                    <input
                      id="oilType"
                      type="texte"
                      placeholder="Asphaltine, GG20"
                      maxLength="50"
                      required
                    />
                  </li>
                  <li style={{ paddingRight: 200 }}>
                    <p>Etablissement:</p>
                    <select
                      id="modifyStation"
                      //defaultValue={item.station}
                      required
                    >
                      {stationList.map((item, key) => (
                        <>
                          <option key={key} value={item.name}>
                            {item.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </li>
                </ul>
                <ul>
                  <li style={{ width: 400 }}>
                    <p>Image:</p>
                    {userImage.preview ? (
                      <img
                        className="soon"
                        src={`${userImage.preview}`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    ) : (
                      <img
                        className="soon"
                        src={`${process.env.REACT_APP_BAC_URL}/images/fluid/defaultFluid.jpg`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    )}
                    <input
                      type="file"
                      name="uimage"
                      onChange={handleImage}
                      className="form-control"
                      id="uploadBtn"
                      accept=".png, .jpg, .jpeg, .x-icon, .ico"
                    />
                  </li>
                  <li style={{ width: 400 }}>
                    <p>Fds:</p>
                    {userFds.preview ? (
                      <img
                        className="soon"
                        src={`${userFds.preview}`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    ) : (
                      <img
                        className="soon"
                        src={`${process.env.REACT_APP_BAC_URL}/images/fluid/defaultFds.jpg`}
                        alt="trash"
                        style={{ width: 100 }}
                      ></img>
                    )}
                    <input
                      type="file"
                      name="uimage"
                      onChange={handleFds}
                      className="form-control"
                      id="uploadBtn"
                      accept=".png, .jpg, .jpeg, .x-icon, .ico"
                    />
                  </li>
                </ul>
                <button>
                  <img
                    className="check"
                    src={check}
                    alt="check"
                    style={{ top: 0, right: 100 }}
                  ></img>{" "}
                </button>
                <img
                  className="returns"
                  src={returns}
                  alt="returns"
                  onClick={() => {
                    setSelect(false);
                    setUserImage("");
                    setUserFds("");
                  }}
                  style={{ top: 0 }}
                ></img>{" "}
              </div>
            </form>
          )}
        </>
      </>
    );
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}
export default AddFluid;
