import React, { useContext } from "react";
import Engin from "../components/Engin";
import OtherEngin from "../components/OtherEngin";
import SignIn from "../components/SignIn";
import { userContext } from "../context/userContext";

function Home() {
  const { enginAvailable, role } = useContext(userContext);
  if (enginAvailable && role) {
    return (
      <>
        {role === "admin" || role === "agent" || role === "teamLeader" ? (
          <div className="home-container">
            <h2 className="home-title">
              Niveaux d'huile des Bidons PM/RED & GRB
            </h2>
            <div className="home">
              {enginAvailable.map((item, key) => (
                <Engin content={item} key={key} />
              ))}
            </div>
            {role === "admin" ? (
              <>
                <h2 className="home-title-other">
                  Tracabilitées des pots de graisse et fluides
                </h2>
                <div className="home">
                  {enginAvailable.map((item, key) => (
                    <OtherEngin content={item} key={key} />
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="home-container">
            {role === "public" ? (
              <h3 className="home-title">oups Pas encore autorisé</h3>
            ) : (
              <></>
            )}
          </div>
        )}
      </>
    );
  } else {
    return <SignIn />;
  }
}

export default Home;
