import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
export default function PasswordReset() {
  const navigate = useNavigate();
  const [token, setToken] = useState(false);
  const urlToken = useLocation();

  async function checkLink() {
    const url = `${process.env.REACT_APP_BAC_URL}/user/checklinkresetpassword`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: urlToken.pathname.substring(16),
      },
    });

    if (response.status === 200) {
      setToken(true);
    } else {
      setToken(false);
    }
  }
  useEffect(() => {
    checkLink();
  });
  async function sendNewPassword(event) {
    event.preventDefault();

    const url = `${process.env.REACT_APP_BAC_URL}/user/sendchangepassword`;
    const userInfo = {
      password: event.target.elements.password.value,
    };
    const data = JSON.stringify(userInfo);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: urlToken.pathname.substring(16),
      },
      body: data,
    });

    if (response.status === 200) {
      alert("Mot de passe modifié avec succes");
      navigate("/connexion");
    } else {
      alert("Une erreur est survenue, veuillez réessayer ultérieurement");
    }
  }
  if (token) {
    return (
      <>
        <h3 className="contact-title">Reinitialisation mot de passe</h3>
        <form onSubmit={sendNewPassword} className="signin-form">
          <div style={{ paddingRight: 20, paddingLeft: 20 }}>
            <label htmlFor="password">Nouveau mot de passe</label>
            <input
              type="password"
              autoComplete="off"
              id="password"
              maxLength="40"
            />
          </div>
          <div style={{ paddingRight: 20, paddingLeft: 20 }}>
            <label htmlFor="confirm-password">
              Confirmer nouveau mot de passe
            </label>
            <input
              type="password"
              autoComplete="off"
              id="confirm-password"
              maxLength="40"
            />
          </div>

          <input type="submit" value="Valider" />
        </form>
      </>
    );
  } else {
    return (
      <>
        <p>Lien invalide</p>
      </>
    );
  }
}
