import React, { useState, useContext, useEffect } from "react";
import { userContext } from "../context/userContext";

import check from "../images/check-mark.ico";
import returns from "../images/x-mark.ico";
import arrow from "../images/arrow-return.ico";
import add from "../images/plus.ico";
import arrowBottom from "../images/arrow-bottom.ico";
import BidonForm from "./BidonForm";
function BidonList(props) {
  const [select, setSelect] = useState(false);
  const [select2, setSelect2] = useState(false);

  const {
    getEnginAvailable,
    station,
    refreshToken,
    fluidList,
    getFluidList,
    role,
  } = useContext(userContext);

  const tankAvailable = props.content.tank;
  async function addTankAvailable(e) {
    e.preventDefault();
    const url = `${process.env.REACT_APP_BAC_URL}/lvl/addtank`;
    const token = localStorage.getItem("XSRF-TOKEN");
    const availableTank = {
      layingDate: e.target.elements.formAddDate.value,
      expirationDate: e.target.elements.formAddDate2.value,
      vac: e.target.elements.formAddVac.value,
      enginAvailibleId: props.content._id,
      tankType: e.target.elements.grbOilTank.value,
      oilType: e.target.elements.typeTank.value,
    };
    const data = JSON.stringify(availableTank);
    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: Bidon ajouté");
      getEnginAvailable(station);

      setSelect2(false);
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  useEffect(() => {
    getFluidList();
  }, [role]);
  return (
    <>
      <div className="capteur-container">
        <div className="engin-array">
          <div className="capteur-title-container">
            <h3>
              {props.content.typeEngin} N° {props.content.idEngin}
            </h3>
            {!select ? (
              <img
                className="arrow-bottom"
                src={arrowBottom}
                alt="arrow-bottom"
                onClick={() => setSelect(true)}
              ></img>
            ) : (
              <img
                className="arrow-top"
                src={arrow}
                alt="arrow-top"
                onClick={() => setSelect(false)}
              ></img>
            )}
          </div>
        </div>
      </div>
      {!select ? (
        <></>
      ) : (
        <div className="engin-array-tittle ">
          <div className="capteur-content-container">
            <h4>Type</h4>
            <h4>Désignation</h4>
            <h4>Equipe</h4>
            <h4>Date mise en place</h4>
            <h4>Date éxpiration</h4>
            <h4 style={{ left: 10 }}>Modifier</h4>
            <h4 style={{ left: 10 }}>Supprimer</h4>
          </div>
        </div>
      )}
      {tankAvailable.map((item, key) => (
        <BidonForm
          content={item}
          key={key}
          idEngin={props.content}
          select={select}
        />
      ))}
      {select ? (
        <>
          {!select2 ? (
            <div className="btn-add-engin">
              <img
                className="add"
                src={add}
                alt="add"
                onClick={() => setSelect2(true)}
              ></img>
            </div>
          ) : (
            <form className="form-bidon-liste" onSubmit={addTankAvailable}>
              <div className="engin-array-info" style={{ height: 50 }}>
                <div className="capteur-content-container">
                  <h3>
                    <select id="grbOilTank" required>
                      {fluidList.map((item, key) => (
                        <>
                          <option key={key} value={item.tankType}>
                            {item.tankType}
                          </option>
                        </>
                      ))}
                    </select>
                  </h3>
                  <h3>
                    <select id="typeTank" required>
                      {fluidList.map((item, key) => (
                        <>
                          <option key={key} value={item.oilType}>
                            {item.oilType}
                          </option>
                        </>
                      ))}
                    </select>
                  </h3>
                  <h3>
                    <input
                      type="texte"
                      id="formAddVac"
                      maxLength="40"
                      required
                      style={{ width: 50, textAlign: "center" }}
                      defaultValue={props.content.vac}
                    ></input>
                  </h3>
                  <h3>
                    <input type="date" id="formAddDate" required></input>
                  </h3>
                  <h3>
                    <input type="date" id="formAddDate2" required></input>
                  </h3>
                  <button>
                    <img className="check" src={check} alt="check"></img>{" "}
                  </button>
                  <h3 onClick={() => setSelect2(false)}>
                    <img className="returns" src={returns} alt="returns"></img>{" "}
                  </h3>
                </div>
              </div>
            </form>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default BidonList;
