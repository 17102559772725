import React, { useEffect, useContext, useState } from "react";
import trash from "../images/trash.ico";
import { userContext } from "../context/userContext";
function MessageAdmin() {
  const { role, refreshToken } = useContext(userContext);
  const [messages, setMessages] = useState();
  const [search, setSearch] = useState();

  async function getMessage() {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/user/getmessage`;

      const options = {
        method: "GET",
        headers: {
          Authorization: "bearer " + token,
        },
        credentials: "include",
      };
      const response = await fetch(url, options);

      let result = await response.json();
      if (response.status === 200) {
        setMessages(result.data);
      } else if (response.status === 401) {
        refreshToken();
      }
    } else {
      return;
    }
  }
  async function deleteMessage(id) {
    if (role === "admin") {
      const token = localStorage.getItem("XSRF-TOKEN");
      const url = `${process.env.REACT_APP_BAC_URL}/user/deletemessage`;
      console.log(id);
      const messageId = {
        messageId: id,
      };
      const data = JSON.stringify(messageId);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: data,
      };
      const response = await fetch(url, options);

      if (response.status === 200) {
        alert("Succès: message supprimée");
        getMessage();
      } else if (response.status === 401) {
        refreshToken();
      } else {
        alert("Une erreur est survenue, veuillez réessayer");
      }
    }
  }
  useEffect(() => {
    getMessage();
  }, []);
  if (role === "admin") {
    if (messages) {
      const newMessages = messages.filter((message) => {
        if (search === "") return messages;
        return (
          message.email.toLowerCase().includes(search) ||
          message.message.toLowerCase().includes(search) ||
          message.role.toLowerCase().includes(search) ||
          message.date.toLowerCase().includes(search) ||
          message.objet.toLowerCase().includes(search)
        );
      });
      return (
        <>
          <div className="engin-array">
            <div className="capteur-title-container">
              <input
                id="search"
                type="text"
                placeholder="Recherche"
                style={{ width: 300, margin: "0 auto" }}
                onChange={(event) => setSearch(event.target.value)}
                value={search}
                maxLength="20"
              ></input>
            </div>
          </div>
          {newMessages && search ? (
            <>
              {newMessages.map((item, key) => (
                <div className="message-array-info " key={key}>
                  <div className="message-content-container">
                    <ul>
                      <li>
                        <p>Email:</p> {item.email}
                      </li>
                      <li>
                        <p>Type:</p>
                        {item.role}
                      </li>
                      <li>
                        <p>Objet:</p>
                        {item.objet}
                      </li>
                      <li>
                        <p>Date:</p>
                        {item.date.substring(3, 15)}
                      </li>
                    </ul>
                    <img
                      className="trash"
                      src={trash}
                      alt="trash"
                      onClick={() => deleteMessage(item._id)}
                    ></img>{" "}
                    <div style={{ marginLeft: 70 }}>
                      <p>Message:</p>
                      {item.message}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {messages.map((item, key) => (
                <div className="message-array-info " key={key}>
                  <div className="message-content-container">
                    <ul>
                      <li>
                        <p>Email:</p> {item.email}
                      </li>
                      <li>
                        <p>Type:</p>
                        {item.role}
                      </li>
                      <li>
                        <p>Objet:</p>
                        {item.objet}
                      </li>
                      <li>
                        <p>Date:</p>
                        {item.date.substring(3, 15)}
                      </li>
                    </ul>
                    <img
                      className="trash"
                      src={trash}
                      alt="trash"
                      onClick={() => deleteMessage(item._id)}
                    ></img>{" "}
                    <div style={{ marginLeft: 70 }}>
                      <p>Message:</p>
                      {item.message}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      );
    }
  } else {
    return (
      <div className="home-container">
        <h3 className="home-title">Acces non autorisé</h3>{" "}
      </div>
    );
  }
}
export default MessageAdmin;
