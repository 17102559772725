import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../context/userContext";
import { Link, useNavigate } from "react-router-dom";
import passwordEye from "../images/eye.ico";
function SignUp() {
  const { stationList, getStationList } = useContext(userContext);
  const [validateEmail, setValidateEmail] = useState("");
  const [validatePassword, setValidatePassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [validationMessage, setValidationMessage] = useState("");

  const [progressBarClass, setProgresseBarClass] = useState("");
  const [passWordType, setPasswordType] = useState("password");
  const [confirmPassWordType, setConfirmPasswordType] = useState("password");
  const navigate = useNavigate();

  const errorDisplay = (tag, message, valid) => {
    const container = document.querySelector("." + tag + "-container");
    const span = document.querySelector("." + tag + "-container > span");

    if (!valid) {
      container.classList.add("error");
      span.textContent = message;
    } else {
      container.classList.remove("error");
      span.textContent = message;
    }
  };

  const emailChecker = (value) => {
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errorDisplay("email", "L'adresse mail n'est pas valide");
      setValidateEmail(null);
    } else {
      errorDisplay("email", "", true);
      setValidateEmail(value);
    }
  };

  const passwordChecker = (value) => {
    setProgresseBarClass("");
    if (
      !value.match(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
      )
    ) {
      errorDisplay(
        "password",
        "Minimum de 8 caractères, une majuscule, un chiffre et un caractère spécial"
      );
      setProgresseBarClass("progressRed");
      setValidatePassword(null);
    } else if (value.length < 12) {
      setProgresseBarClass("progressBlue");
      errorDisplay("password", "Mots de passe correct", true);
      setValidatePassword(value);
    } else {
      setProgresseBarClass("progressGreen");
      errorDisplay("password", "Mots de passe sécurisé", true);
      setValidatePassword(value);
    }
    if (confirmPass) confirmChecker(confirmPass);
  };

  const confirmChecker = (value) => {
    console.log(validatePassword);
    if (value !== validatePassword) {
      errorDisplay("confirm", "Les mots de passe ne correspondent pas");
      setConfirmPass(false);
    } else {
      errorDisplay("confirm", "", true);
      setConfirmPass(true);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const station = event.target.elements.signUpStation.value;
    if (validateEmail && validatePassword && confirmPass) {
      const url = `${process.env.REACT_APP_BAC_URL}/user/signup`;
      const userInfo = {
        password: validatePassword,
        email: validateEmail,
        station: station,
        pseudo: validateEmail,
        role: "public",
      };
      const data = JSON.stringify(userInfo);
      console.log(userInfo);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      });

      if (response.status === 200) {
        setValidateEmail(null);
        setValidatePassword(null);
        setConfirmPass(null);
        setValidationMessage("");

        navigate("/inscriptionreussi");
      } else {
        setValidationMessage("Une erreur est survenue veuillez réessayer");
      }
      if (response.status === 500) {
        setValidationMessage("adresse mail déjà utilisé");
      }
    } else {
      setValidationMessage("Veuillez remplir correctement les champ");
    }
  }

  useEffect(() => {
    getStationList();
  }, []);

  return (
    <>
      <h3 className="contact-title">Inscription</h3>
      <form onSubmit={handleSubmit} className="signup-form">
        <div className="pseudo-container" style={{ paddingLeft: 20 }}>
          <label htmlFor="station">Etablissement</label>
          <select id="signUpStation" type="text" required>
            <option value="N.C">N.C</option>
            {stationList.map((item, key) => (
              <option key={key} value={item.name}>
                {item.info}({item.name})
              </option>
            ))}
          </select>
        </div>

        <div
          className="email-container"
          style={{ paddingRight: 20, paddingLeft: 20 }}
        >
          <label htmlFor="email">Email</label>
          <input
            required
            type="text"
            autoComplete="off"
            id="email"
            maxLength="50"
            onChange={(e) => emailChecker(e.target.value)}
          />
          <span></span>
        </div>

        <div
          className="password-container"
          style={{ paddingRight: 20, paddingLeft: 20 }}
        >
          <label htmlFor="password">Mot de passe</label>
          <input
            type={passWordType}
            autoComplete="off"
            id="password"
            maxLength="40"
            onChange={(e) => passwordChecker(e.target.value)}
            required
          ></input>
          {passWordType === "password" ? (
            <img
              className="passwordEye"
              src={passwordEye}
              alt="bottom show password"
              onClick={() => setPasswordType("text")}
            ></img>
          ) : (
            <img
              className="passwordEye"
              src={passwordEye}
              alt="bottom show password"
              onClick={() => setPasswordType("password")}
            ></img>
          )}
          <p className={progressBarClass} id="progress-bar"></p>
          <span></span>
        </div>
        <div
          className="confirm-container"
          style={{ paddingRight: 20, paddingLeft: 20 }}
        >
          <label htmlFor="password">Confirmer mots de passe</label>
          <input
            type={confirmPassWordType}
            autoComplete="off"
            id="confirm"
            maxLength="40"
            onChange={(e) => confirmChecker(e.target.value)}
            required
          />
          {confirmPassWordType === "password" ? (
            <img
              className="passwordEye"
              src={passwordEye}
              alt="bottom show password"
              onClick={() => setConfirmPasswordType("text")}
            ></img>
          ) : (
            <img
              className="passwordEye"
              src={passwordEye}
              alt="bottom show password"
              onClick={() => setConfirmPasswordType("password")}
            ></img>
          )}

          <span></span>
        </div>
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          {" "}
          <label>
            En créant un compte, vous acceptez les{" "}
            <Link to="/cgu" style={{ textDecoration: "underline" }}>
              Conditions générales d'utilisation
            </Link>
            . Vous pouvez consulter notre{" "}
            <Link to="/cgu" style={{ textDecoration: "underline" }}>
              notice de Protection de vos Informations Personnelles
            </Link>{" "}
            et notre{" "}
            <Link to="/cgu" style={{ textDecoration: "underline" }}>
              notice Cookies.
            </Link>
          </label>
        </div>

        <span className="validation-span">{validationMessage}</span>

        <input type="submit" value="S'inscrire" />
      </form>
    </>
  );
}

export default SignUp;
